import React, { useMemo } from 'react';

const LiveExecutionsChart = ({ data }) => {
  console.log("LiveExecutionsChart render with data:", data);

  return null
  
  /*
  const sortedData = useMemo(() => {
    if (!data || data.length === 0) return [];
    
    // Sort data by timestamp in reverse order (newest first)
    const sorted = [...data].sort((a, b) => b.created_at - a.created_at);
    
    return sorted.map(point => ({
      ...point,
      displayTime: new Date(point.created_at * 1000).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      })
    }));
  }, [data]);

  if (!data || data.length === 0) {
    console.log("No data available");
    return (
      <div style={{ width: '100%', height: 300 }} className="bg-gray-800 flex items-center justify-center text-gray-400">
        No data available
      </div>
    );
  }

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <LineChart 
          data={sortedData}
          margin={{ top: 10, right: 30, left: 10, bottom: 25 }}
        >
          <CartesianGrid stroke="#444" />
          <XAxis 
            dataKey="displayTime"
            type="category"
            angle={-45}
            textAnchor="end"
            height={60}
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis 
            domain={['auto', 'auto']}
          />
          <Tooltip 
            labelFormatter={(label) => label}
            formatter={(value, name) => [value, name.charAt(0).toUpperCase() + name.slice(1)]}
          />
          <Legend />
          <Line 
            type="monotone" 
            dataKey="finished" 
            name="Finished"
            stroke="#00F670"
            dot={{ r: 2 }}
          />
          <Line 
            type="monotone" 
            dataKey="failed" 
            name="Failed"
            stroke="#FF4444"
            dot={{ r: 2 }}
          />
          <Line 
            type="monotone" 
            dataKey="aborted" 
            name="Aborted"
            stroke="#FFB020"
            dot={{ r: 2 }}
          />
          <Line 
            type="monotone" 
            dataKey="executing" 
            name="Executing"
            stroke="#60A5FA"
            dot={{ r: 2 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
  */
}

export default LiveExecutionsChart;
