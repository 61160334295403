import React, { useState, useEffect, useContext, memo } from 'react';

import ReactGA from 'react-ga4';
import { useNavigate, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify"

import {
	Done as DoneIcon,
	Clear as ClearIcon,
	AddTask as AddTaskIcon,
	Warning as WarningIcon,
} from '@mui/icons-material';

import {
	Tooltip,
	Slider,
	Divider,
	List,
	ListItem,
	ListItemText,
	Card,
	CardContent,
	Grid,
	Typography,
	Button,
	ButtonGroup,
	FormControl,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Paper,
	Table,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
} from '@mui/material';

import FAQList from '../components/FAQList.jsx';
import Newsletter from "../components/Newsletter.jsx";
import Services from "./Services.jsx";
import { Context } from '../context/ContextApi.jsx';

//export const typecost = 0.0018
export const typecost = 0.0032
export const typecost_single = (typecost * 2.33).toFixed(4)
export const corecost = 120

export const handlePayasyougo = (userdata, selectedOrganization, BillingEmail) => {
	var billingurl = "https://billing.stripe.com/p/login/bIY5lo5bMbWs9Py5kk"

	if (selectedOrganization !== undefined && selectedOrganization !== null) {
		if (BillingEmail !== undefined && BillingEmail !== null && BillingEmail !== "") {
			billingurl += `?prefilled_email=${BillingEmail}`
		}
		else if (selectedOrganization.Billing !== undefined && selectedOrganization.Billing !== null && selectedOrganization.Billing.Email !== "" && selectedOrganization.Billing.Email !== undefined && selectedOrganization.Billing.Email !== null) {
			billingurl += `?prefilled_email=${selectedOrganization.Billing.Email}`

		} else if (userdata.username !== undefined && userdata.username !== null && userdata.username !== "") {
			billingurl += `?prefilled_email=${userdata.username}`
		}
	}

	toast("Redirecting in 2 seconds. Use the organization's billing email.")
	setTimeout(() => {
		//window.location = billingurl 
		window.open(billingurl, "_blank")
	}, 2500)
}

// 1. Create 2-3 payment tiers (slider?)
// 2. Create a way to show them anywhere
//
// Site references:
// https://logz.io/pricing/
// https://www.avanan.com/pricing
const PaymentField = (props) => {
	const { maxFields, theme, removeAdditions, isLoggedIn } = props

	const isCloud = typeof window === 'undefined' || window === undefined || window.location === undefined ? true : window.location.host === "localhost:3002" || window.location.host === "shuffler.io";

	// Multiple unused variables here
	let navigate = useNavigate();
	const parsedFields = maxFields === undefined ? 300 : maxFields
	const [variant, setVariant] = useState(0)
	const [shuffleVariant, setShuffleVariant] = useState(isCloud ? 0 : 1)
	const [paymentType, setPaymentType] = useState(0)
	const [modalOpen, setModalOpen] = useState(false)
	const [showPricing,] = useState(true)
	const [currentPrice, setCurrentPrice] = useState(129)
	const [isLoaded, setIsLoaded] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [highlight, setHighlight] = useState(false)
	const [isPriceing, setIsPriceing] = useState(true)

	// Cloud
	const [calculatedApps, setCalculatedApps] = useState(960)
	const [calculatedCost, setCalculatedCost] = useState("$960")
	const [selectedValue, setSelectedValue] = useState(960)
	// MSSP
	const [selectedValueMSSP, setSelectedValueMSSP] = useState(960);
	const [calculatedCostMSSP, setCalculatedCostMSSP] = useState("$960");

	// Onprem
	const [calculatedCores, setCalculatedCores] = useState('960')
	const [onpremSelectedValue, setOnpremSelectedValue] = useState(8)

	const payasyougo = "Pay as you go"

	useEffect(() => {
		console.log("New variant: ", shuffleVariant)

		if (shuffleVariant === 1) {
			setCalculatedCost("$960")
			setSelectedValue(8)
			setCalculatedCostMSSP("$960");
			setSelectedValueMSSP(8);
		} else {
			setCalculatedCost("$960")
			setSelectedValue(300)
			setCalculatedCostMSSP("$960");
			setSelectedValueMSSP(300);
		}
	}, [shuffleVariant])

	if (typeof window === 'undefined' || window.location === undefined) {
		return null
	}


	const handleChange = (event, newValue) => {
		if (shuffleVariant === 1) {
			setSelectedValue(newValue)
			if (newValue === 32) {
				setCalculatedCost(`Get A Quote`)
			} else {
				setCalculatedCost(`$${newValue * corecost}`)
			}
		} else {
			setSelectedValue(newValue)
			if (newValue < 300) {
				setCalculatedCost(`Pay as you go`)
			} else if (newValue === 1000) {
				setCalculatedCost(`Get A Quote`)
			} else {
				setCalculatedCost(`$${newValue * 1000 * typecost}`)
			}
		}
	}

	const handleChangeMSSP = (event, newValue) => {
		if (shuffleVariant === 1) {
			setSelectedValueMSSP(newValue);
			if (newValue === 32) {
				setCalculatedCostMSSP(`Get A Quote`);
			} else {
				setCalculatedCostMSSP(`$${newValue * corecost}`);
			}
		} else {
			setSelectedValueMSSP(newValue);
			if (newValue < 300) {
				setCalculatedCostMSSP(`Pay as you go`);
			} else if (newValue === 1000) {
				setCalculatedCostMSSP(`Get A Quote`);
			} else {
				setCalculatedCostMSSP(`$${newValue * 1000 * typecost}`);
			}
		}
	};


	//	return `${value}`;
	//}

	if (!isLoaded) {
		setIsLoaded(true)

		const tmpsearch = typeof window === 'undefined' || window.location === undefined ? "" : window.location.search
		const tmpVar = new URLSearchParams(tmpsearch).get("variant")
		if (tmpVar !== undefined && tmpVar !== null && tmpVar < 3) {
			setVariant(parseInt(tmpVar))
		}

		const tmpType = new URLSearchParams(tmpsearch).get("payment_type")
		if (tmpType !== undefined && tmpType !== null && tmpType < 2) {
			setPaymentType(parseInt(tmpType))
		}

		const modal = new URLSearchParams(tmpsearch).get("payment_modal")
		if (modal !== undefined && modal !== null && modal === "open") {
			setModalOpen(true)
		}

		const tmpView = new URLSearchParams(tmpsearch).get("view")
		if (tmpView !== undefined && tmpView !== null && tmpView === "failure") {
			setErrorMessage("Something went wrong with your payment. Please try again.")
		}

		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		const foundTab = params["tab"];
		if (foundTab !== null && foundTab !== undefined) {
			if (foundTab === "onprem") {
				//valuetext(8, 1) 
				setShuffleVariant(1)
				//setCalculatedCores(`$${8*corecost}`)
			}
		}

		const foundHighlight = params["highlight"];
		if (foundHighlight !== null && foundHighlight !== undefined) {
			setHighlight(true)
		}
	}

	const billingInfo = <Typography variant="body2" color="textSecondary" style={{ marginTop: 30, }} >Billed anually or monthly at 1.2x the cost</Typography>
	//const skipFreemode = window.location.pathname.startsWith("/admin") 
	const skipFreemode = false
	const maxwidth = isMobile ? "91%" : skipFreemode ? 1100 : 1200
	const activeIcon = <DoneIcon style={{ color: "green" }} />
	const inActiveIcon = <ClearIcon style={{ color: "red" }} />

	const questions = [
		{
			"question": "What currency are your prices in?",
			"answer": "They are in US Dollars.",
		},
		{
			"question": "What payment methods do you offer?",
			"answer": "We accept wire & bank transfer, credit cards, Apple Pay, Google Pay and any other payment Stripe supports.",
		},
		{
			"question": "When does my membership get activated?",
			"answer": "As soon as the payment is finished, you should see more features available in the Admin view."
		},
		{
			"question": "What happens after payment is finished?",
			"answer": "We will automatically and immediately apply all the features to your organization.",
		},
		{
			"question": "What is your refund policy?",
			"answer": "For monthly and yearly subscriptions, you have 48 hours after the transaction to request a refund. Note that we reserve the right to decline requests if we detect high activity on your account within this time.",
		},
		{
			"question": "Do you offer discounts or free trials?",
			"answer": "We offer free trials, and may offer discounts and features for testing in certain scenarios.",
		},
		{
			"question": "How can I switch to annual billing?",
			"answer": "Contact us at Contact Page!",
		},
		{
			"question": "How can I switch my plan?",
			"answer": "Contact us at Contact Page!",
		},
		{
			"question": "How can I cancel my plan?",
			"answer": "As an Admin of your organization, you can manage it from the Admin page.",
		},
		{
			"question": "Do you offer support?",
			"answer": "Yes! We offer priority support with an SLA to our enterprise customers, and will answer any questions directed our way on the Contact page otherwise.",
		},
		{
			"question": "Can you help me automate my operations?",
			"answer": "Yes! We offer support with setup, configuration, automation and app creation. This can be bought as an addition withour needing a subscription.",
		},
	]

	// All triggers
	const features = [
		{
			"name": "Scalable, Fast Workflows",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"cloud": false,
		},
		{
			"name": "High Availability",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"cloud": false,
		},

		{
			"name": "Users",
			"basic": "Unlimited",
			"community": "Unlimited",
			"pro": "Unlimited",
			"enterprise": "",
			"active": true,
			"cloud": false,
		},
		{
			"name": "Activated Apps",
			"basic": "Unlimited",
			"community": "Unlimited",
			"pro": "Unlimited",
			"enterprise": "",
			"active": true,
			"cloud": false,
		},
		{
			"name": "Workflows",
			"basic": "Unlimited",
			"community": "Unlimited",
			"pro": "Unlimited",
			"enterprise": "",
			"active": true,
			"cloud": false,
		},
		{
			"name": "Users",
			"basic": "5",
			"community": "Unlimited",
			"pro": "Unlimited",
			"enterprise": "",
			"active": true,
			"onprem": false,
		},
		{
			"name": "Apps",
			"basic": "50",
			"community": "Unlimited",
			"pro": "Unlimited",
			"enterprise": "",
			"active": true,
			"onprem": false,
		},
		{
			"name": "Workflows",
			"basic": "10",
			"community": "Unlimited",
			"pro": "Unlimited",
			"enterprise": "",
			"active": true,
			"onprem": false,
		},

		{
			"name": "App Runs",
			"basic": "10k / month",
			"community": "10k / month",
			"pro": "As per License",
			"enterprise": "",
			"active": true,
			"onprem": false,
		},
		{
			"name": "Workflow App Runs",
			"basic": "Unlimited",
			"community": "Unlimited",
			"pro": "Unlimited",
			"enterprise": "",
			"active": true,
			"cloud": false,
		},
		{
			"name": "File Storage",
			"basic": "Max 1GB",
			"community": "Yes",
			"pro": "Default 100 GB",
			"enterprise": "",
			"active": true,
			"onprem": false,
		},
		{
			"name": "File Storage",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"cloud": false,
		},
		{
			"name": "Per-CPU-core support",
			"basic": "0 / month",
			"community": "0 / month",
			"pro": "Pay as you go",
			"enterprise": "8 / month",
			"active": true,
			"cloud": false,
		},
		{
			"name": "Shuffle SMS alerting",
			"basic": "30 / month",
			"community": "Yes",
			"pro": "300 / month",
			"enterprise": "300 / month",
			"active": true,
		},
		{
			"name": "Shuffle Email alerting",
			"basic": "100 / month",
			"community": "Yes",
			"pro": "10k / month",
			"enterprise": "",
			"active": true,
		},

		{
			"name": "Multi-Tenancy",
			"basic": "Max 3 Tenants",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"title": "MSSP Features",
		},
		{
			"name": "Authentication Groups",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Multi-Tenant Workflows",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Multiple Runtime Environments",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Multi-Tenant User Management",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Detailed Development Support",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Reselling Support",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},

		{
			"name": "Billing Control & Alerts",
			"basic": "No",
			"community": "No",
			"pro": "Per Tenant",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Detailed App-Run tracking",
			"basic": "No",
			"community": "No",
			"pro": "Per Tenant & Workflow",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Datacenter Region Selection",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Audit logging",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},

		{
			"name": "Priority Support",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"title": "Support & Success",
		},
		{
			"name": "Maintenance & Updates",
			"basic": "No",
			"community": "",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"cloud": false,
		},
		{
			"name": "Documentation & Community Support",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Email & Chat Support",
			"basic": "support@shuffler.io",
			"community": "No",
			"pro": "Prioritized + Critical issue SLA",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Personal onboarding",
			"basic": "No",
			"community": "",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Workflow editor",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"title": "Basic features",
		},
		{
			"name": "App editor",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Private Apps",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Default & Shared playbooks",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Organization control",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Autocomplete features",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Hybrid Webhook trigger",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Hybrid User Input trigger",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Hybrid Email trigger",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Hybrid Schedule",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Failure Notifications",
			"basic": "Yes",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Hybrid Executions",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Use of Public Workflows",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Automatic Platform updates",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"onprem": false,
		},
		{
			"name": "Uptime SLA",
			"basic": "No",
			"community": "No",
			"pro": "99.9%",
			"enterprise": "",
			"active": true,
			"onprem": false,
		},
		{
			"name": "Automatic backups",
			"basic": "No",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"onprem": false,
		},





		{
			"name": "2-factor authentication",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"title": "Security & Development",
		},
		{
			"name": "SAML / SSO",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "API-key management (KMS)",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Role-based access control",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},

		{
			"name": "Workflow recommendations",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
			"title": "Additional Features",
		},
		{
			"name": "Standardized app categories",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "App Framework",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Hybrid search engine",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},

		{
			"name": "Hybrid App syncronization",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Execution Retention",
			"basic": "1 Month",
			"community": "Yes",
			"pro": "1 Year default",
			"enterprise": "",
			"active": true,
			"onprem": false,
		},
		{
			"name": "Workflows as Functions",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Workflows as Apps",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": false,
		},
		{
			"name": "Workflows as Triggers",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": false,
		},
		{
			"name": "Detection Management",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": false,
		},
		{
			"name": "Mitre Att&ck integrations",
			"basic": "No",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": false,
		},
		{
			"name": "Open Source account rollback",
			"basic": "Yes",
			"community": "Yes",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Data LOCATION control",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Data RETENTION control",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": false,
		},
		{
			"name": "Shuffle IoC search",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": false,
		},
		{
			"name": "Controllable Reporting",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": false,
		},
		{
			"name": "Management dashboard",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Risk based overview",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": false,
		},
		{
			"name": "Compliance dashboard",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": false,
		},
		{
			"name": "Log Ingestion (Data Lake)",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Data Lake Searching (SIEM)",
			"basic": "No",
			"community": "No",
			"pro": "Yes",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "App & Workflow Training",
			"basic": "See Training Page",
			"community": "See Training Page",
			"pro": "See Training Page",
			"enterprise": "",
			"active": true,
			"title": "Professional Services",
		},
		{
			"name": "Developer Training",
			"basic": "See Training Page",
			"community": "See Training Page",
			"pro": "See Training Page",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Custom App Development",
			"basic": "Contact us",
			"community": "No",
			"pro": "Contact us",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "Custom Workflow Development",
			"basic": "Contact us",
			"community": "No",
			"pro": "Contact us",
			"enterprise": "",
			"active": true,
		},
		{
			"name": "White-Labeling",
			"basic": "Contact us",
			"community": "No",
			"pro": "Contact us",
			"enterprise": "",
			"active": true,
		},

	]

	const itemStyle = {
		display: 'flex',
		alignItems: 'center',
		margin: '8px 0'
	};

	const defaultTaskIcon = <AddTaskIcon style={{ marginRight: 5, marginTop: 5, }} />
	const stripe = typeof window === 'undefined' || window.location === undefined ? "" : props.stripeKey === undefined ? "" : window.Stripe ? window.Stripe(props.stripeKey) : ""
	const handleStripeRedirect = (payment_type, recurrence) => {
		console.log("REDIRECT: ", payment_type, recurrence)

		// FIXME: Proper redirect cycle here
		if (props.userdata === undefined || props.userdata.username === undefined || props.userdata.active_org === undefined || props.userdata.active_org.id === undefined) {
			console.log("User must sign in and have an organization first. Current: ", props.userdata)
			// 1. Add query parameters: Yearly / monthly, Community / pro
			navigate(`/register?view=pricing&variant=${variant}&payment_type=${paymentType}&payment_modal=open&message=You need to create a user to continue`)
			return
		} else {
			console.log("Username is ", props.userdata.username)
		}

		//payment_type = community(0), pro(1)
		//recurrence = yearly(0), monthly(1)

		// recurrence = 0 = yearly
		// recurrence = 1 = monthly 

		var priceItem = "price_1Hh8ecDzMUgUjxHSPEdeueyu"
		var text = "enterprise_yearly_pay_click"
		if (payment_type === 0) {
			console.log("Handling payment type 0: hybrid")
			priceItem = recurrence === 0 ? isCloud ? "price_1HhAOgDzMUgUjxHSmesUZkNU" : "price_1HhAOgDzMUgUjxHSmesUZkNU" : isCloud ? "price_1HhAOgDzMUgUjxHSfU8XzQ84" : "price_1HhAOgDzMUgUjxHSfU8XzQ84"

			ReactGA.event({
				category: "pricing",
				action: `hybrid_pay_click`,
				label: "",
			})
		} else if (payment_type === 1) {
			console.log("Handling payment type 1: enterprise")
			priceItem = recurrence === 0 ? isCloud ? "price_1HhAdrDzMUgUjxHSsIDOCYgm" : "price_1HhAdrDzMUgUjxHSsIDOCYgm" : isCloud ? "price_1HhAdrDzMUgUjxHS7Cu5vF95" : "price_1HhAdrDzMUgUjxHS7Cu5vF95"

			if (recurrence === 1) {
				text = "enterprise_monthly_pay_click"
			}

			ReactGA.event({
				category: "pricing",
				action: text,
				label: "",
			})
		} else if (payment_type === 2) {
			console.log("Handling payment type 2: basic")
			priceItem = recurrence === 0 ? isCloud ? "price_1HnPmWDzMUgUjxHSzEHV5e6t" : "price_1HlvuPDzMUgUjxHS1pvtPONJ" : isCloud ? "price_1HnPmWDzMUgUjxHSGC3Yiact" : "price_1HlvuPDzMUgUjxHSrp7Ws8iu"

			ReactGA.event({
				category: "pricing",
				action: `mssp_pay_click`,
				label: "",
			})
		} else {
			console.log(`No handler for redirect ${payment_type} yet`)
			return
		}

		// Current URL + status = Success/fail
		const successUrl = `${window.location.origin}/admin?payment=success`
		const failUrl = `${window.location.origin}/pricing?view=failure&variant=${variant}&payment_type=${paymentType}`

		var checkoutObject = {
			lineItems: [
				{ price: priceItem, quantity: 1 },
			],
			mode: "subscription",
			billingAddressCollection: "auto",
			successUrl: successUrl,
			cancelUrl: failUrl,
			submitType: "donate",
			clientReferenceId: props.userdata.active_org.id,
		}

		stripe.redirectToCheckout(checkoutObject)
			.then(function (result) {
				console.log("SUCCESS STRIPE?: ", result)

				text += "_success"
				ReactGA.event({
					category: "pricing",
					action: text,
					label: "",
				})
			})
			.catch(function (error) {
				console.error("STRIPE ERROR: ", error)
				text += "_fail"
				ReactGA.event({
					category: "pricing",
					action: text,
					label: "",
				})
			});

		console.log("Done with payment!")
	}

	const buttonBackground = "linear-gradient(to right, #f86a3e, #f34079)"
	const level1Button =
		<Button fullWidth variant="contained" color="primary" style={{ borderRadius: 25, height: 40, margin: "15px 0px 15px 0px", fontSize: 14, color: "white", backgroundImage: buttonBackground, }} onClick={() => {
			setMonthlyCost(0, paymentType)
			setVariant(0)
			setModalOpen(true)
			ReactGA.event({
				category: "pricing",
				action: `hybrid_click`,
				label: "",
			})
		}}>
			Get hybrid access
		</Button>

	// const level2Button =
	// 	<Button fullWidth disabled={false} variant="outlined" color="primary" style={{ marginTop: shuffleVariant === 0 ? 20 : 45, borderRadius: 25, height: 40, fontSize: 14, color: isLoggedIn && shuffleVariant === 0 ? theme.palette.mainColor : "white", backgroundImage: isLoggedIn && shuffleVariant === 0 ? "inherit" : buttonBackground, }} onClick={() => {
	// 		//setMonthlyCost(1, paymentType)
	// 		//setVariant(1)
	// 		//setModalOpen(true)
	// 		ReactGA.event({
	// 			category: "pricing",
	// 			action: `enterprise_click`,
	// 			label: "",
	// 		})

	// 		ReactGA.event({
	// 			category: "pricing",
	// 			action: `demo_click`,
	// 			label: "",
	// 		})

	// 		if (window.drift !== undefined) {
	// 			window.drift.api.startInteraction({ interactionId: 340045 })
	// 		}
	// 	}}>
	// 		Get a demo
	// 	</Button>

	const level3Button = skipFreemode ? null :
		<Button fullWidth disabled={false} variant="contained" color="primary" style={{ textTransform: "capitalize", borderRadius: 25, height: 40, margin: "15px 0px 15px 0px", fontSize: 17, color: "white", backgroundImage: buttonBackground, }} onClick={() => {

			ReactGA.event({
				category: "pricing",
				action: `getting_started_click`,
				label: "",
			})

			if (shuffleVariant === 0) {
				navigate("/register?message=Get started for free")
			} else {
				window.location.href = "https://github.com/Shuffle/Shuffle/blob/master/.github/install-guide.md"
			}

			//setMonthlyCost(2, paymentType)
			//setVariant(2)
			//setModalOpen(true)
		}}>
			Start building!
		</Button>


	const cardStyle = {
		// height: "100%",
		width: "100%",
		textAlign: "center",
		backgroundColor: theme.palette.surfaceColor,
		color: "white",
		borderRadius: theme.palette?.borderRadius,
	}

	const isLoggedInHandler = () => {
		if (calculatedCost === payasyougo) {
			handlePayasyougo(props.userdata);
			return;
		}

		const priceItem =
			window.location.origin === "https://shuffler.io/"
				? shuffleVariant === 0
					? "app_executions"
					: "cores"
				: shuffleVariant === 0
					? "price_1PZPSSEJjT17t98NLJoTMYja"
					: "price_1PZPQuEJjT17t98N3yORUtd9";

		const successUrl = `${window.location.origin}/admin?admin_tab=billingstats&payment=success`;
		const failUrl = `${window.location.origin}/pricing?admin_tab=billingstats&payment=failure`;

		console.log("Priceitem: ", priceItem, shuffleVariant);

		let quantity;
		if (shuffleVariant === 0) {
			quantity = selectedValue / 100;
		} else {
			quantity = selectedValue;
		}

		redirectToCheckout(priceItem, quantity, successUrl, failUrl);
	};

	const redirectToCheckout = (priceItem, quantity, successUrl, failUrl) => {
		const checkoutObject = {
			lineItems: [
				{
					price: priceItem,
					quantity: quantity,
				},
			],
			mode: "subscription",
			billingAddressCollection: "auto",
			successUrl: successUrl,
			cancelUrl: failUrl,
			clientReferenceId: props.userdata.active_org.id,
		};

		stripe
			.redirectToCheckout(checkoutObject)
			.then(function (result) {
				console.log("SUCCESS STRIPE?: ", result);

				ReactGA.event({
					category: "pricing",
					action: "add_card_success",
					label: "",
				});
			})
			.catch(function (error) {
				console.error("STRIPE ERROR: ", error);

				ReactGA.event({
					category: "pricing",
					action: "add_card_error",
					label: "",
				});
			});
	};


	var indexskip = 0

	const listItemTitle =
		<ListItem style={{ backgroundColor: theme.palette.surfaceColor, borderBottom: "1px solid rgba(255,255,255,0.5)", }}>
			<ListItemText
				primary=""
				style={{ textAlign: "left", width: 150, maxWidth: isMobile ? 130 : "100%", }}
			/>
			<ListItemText
				primary=<b>{isMobile ? "F" : shuffleVariant === 0 ? "Free" : "Open Source"}</b>
				style={{ textAlign: "left", flex: 2 }}
			/>
			<ListItemText
				primary=<b>{isMobile ? "E" : shuffleVariant === 0 ? "Scale" : "Speed & Scale"}</b>
				style={{ textAlign: "left", flex: 2 }}
			/>
		</ListItem>

	const topRet =
		<div style={{ textAlign: "center" }}>
			<div style={{ width: "100%", margin: "auto", backgroundColor: theme.palette.backgroundColor, position: "sticky", top: -75, paddingBottom: 20, zIndex: 1000, }}>
				<ButtonGroup style={{ height: 50, marginTop: 100, }} color="primary" aria-label="outlined secondary button group">
					<Button style={{ width: 150, textTransform: "none", }} variant={shuffleVariant === 0 ? "contained" : "outlined"} onClick={(event) => {
						event.preventDefault()

						setShuffleVariant(0)
						ReactGA.event({
							category: "pricing",
							action: `saas_normal_click`,
							label: "",
						})

						navigate("/pricing?tab=cloud")
					}}>Cloud</Button>
					<Button style={{ width: 150, textTransform: "none", }} variant={shuffleVariant === 1 ? "contained" : "outlined"} onClick={(event) => {
						event.preventDefault()

						setShuffleVariant(1)
						navigate("/pricing?tab=onprem")

						ReactGA.event({
							category: "pricing",
							action: `self_hosted_normal_click`,
							label: "",
						})

					}}>Self-Hosted</Button>
				</ButtonGroup>
			</div>
			{errorMessage.length > 0 ? <Typography variant="h4">Error: {errorMessage}</Typography> : null}
			<div style={{ display: isMobile ? null : "flex", margin: isMobile ? null : "auto", marginTop: isMobile ? null : 100, }}>
				<Grid container spacing={4} style={{ width: maxwidth / 2.7 * 2.1, margin: isMobile ? null : "auto", marginTop: isMobile ? "auto" : null, padding: isMobile ? 20 : null }}>
					{skipFreemode ? null :
						<Grid item xs={isMobile ? 12 : 6}>
							<Card style={cardStyle}>
								<CardContent style={{ padding: 35, backgroundColor: "#212121" }}>
									<Typography variant="h4" style={{ color: "#f86a3e" }}>{shuffleVariant === 0 ?
										"Trial"
										:
										<a rel="noreferrer noopener" target="_blank" style={{ textDecoration: "none", color: "#f85a3e", }} href="https://github.com/shuffle/shuffle">Open Source</a>
									}</Typography>
									<Typography variant="h3" style={{ marginTop: 18, }}>{paymentType === 0 ? "Free" : "Free"}</Typography>
									<Typography variant="body1" color="textSecondary" style={{ marginBottom: 100, }}>
										{shuffleVariant === 0 ?
											"per month for 10k app runs."
											:
											"until the end of time."
										}

									</Typography>
									<div style={{ textAlign: 'left', color: '#fff', borderRadius: '8px' }}>
										<Typography style={itemStyle}>
											{defaultTaskIcon} <Typography variant="body1" style={{ marginLeft: '10px' }}>{shuffleVariant === 0 ? '10 Workflows' : 'Unlimited Workflows, Apps, and Users'}</Typography>
										</Typography>

										<span>
											<Divider style={{ backgroundColor: '#444' }} />
											<Typography style={itemStyle}>
												{defaultTaskIcon} <Typography variant="body1" style={{ marginLeft: '10px' }}>{shuffleVariant === 0 ? '50 Apps' : 'SSO & MFA'}</Typography>
											</Typography>
										</span>
										<Divider style={{ backgroundColor: '#444' }} />
										<Typography style={itemStyle}>
											{defaultTaskIcon} <Typography variant="body1" style={{ marginLeft: '10px' }}>{shuffleVariant === 0 ? '5 Users' : 'No uptime SLA'}</Typography>
										</Typography>
										<Divider style={{ backgroundColor: '#444' }} />
										<Typography style={itemStyle}>
											{defaultTaskIcon} <Typography variant="body1" style={{ marginLeft: '10px' }}>{shuffleVariant === 0 ? '1 Onprem Runner' : 'Community Support'}</Typography>
										</Typography>
										{/* <Divider style={{ backgroundColor: '#444' }} /> */}
										{/* <Typography style={itemStyle}>
											{defaultTaskIcon} <Typography variant="body1" style={{ marginLeft: '10px' }}>{shuffleVariant === 0 ? '0 hours of Professional Services' : '0 hours of Professional Services'}</Typography>
										</Typography>
										<Divider style={{ backgroundColor: '#444' }} />
										<Typography style={itemStyle}>
											{defaultTaskIcon} <Typography variant="body1" style={{ marginLeft: '10px' }}>{shuffleVariant === 0 ? 'and more, see below.' : 'and more, see below.'}</Typography>
										</Typography> */}
										{/* <Divider style={{ backgroundColor: '#444' }} /> */}
										{/* <Typography style={itemStyle}>
											{defaultTaskIcon}
											<Typography variant="body1" style={{ marginLeft: '10px' }}>
												<span
													style={{ cursor: 'pointer', color: '#f86a3e', marginRight: '5px' }}
													onClick={() => {
														if (window.drift !== undefined) {
															window.drift.api.startInteraction({ interactionId: 340043 });
														} else {
															console.log("Couldn't find drift in window.drift and not .drift-open-chat with querySelector: ", window.drift);
														}
													}}
												>
													Free Support
												</span>
												&
												<a
													rel="noreferrer noopener"
													target="_blank"
													style={{ textDecoration: 'none', color: '#f85a3e', marginLeft: '5px' }}
													href="https://github.com/shuffle/shuffle"
												>
													Discord access
												</a>
											</Typography>
										</Typography> */}
									</div>
									<div style={{ height: shuffleVariant === 0 ? 158 : 135 }} />
									{level3Button}
								</CardContent>
							</Card>
						</Grid>
					}

					<Grid item xs={isMobile ? 12 : 6}>
						<img src="images/circle.png" alt="Shuffle MSSP and Enterprise" style={{
							position: "absolute",
							width: isMobile ? 90 : 145,
							paddingLeft: isMobile ? 102 : 125,
							top: isLoggedIn? isMobile ? 935:210 : !isLoggedIn? isMobile ? 935:270 : shuffleVariant === 0 ? isMobile ? 935:210 :isMobile ? 965:210,
						}} />
						<Card style={{
							...cardStyle,
							border: "solid 2px transparent",
							// borderImageSlice: 1,
							background: "linear-gradient(90deg, #F86744 0%, #F34475 100%) border-box",
							// backgroundColor: "#212121",
							overflow: "hidden"
						}}>
							<CardContent style={{ padding: 35, backgroundColor: "#212121" }}>
								<Typography variant="h4" style={{ color: "#f86a3e" }}>{shuffleVariant === 1 ? "Speed & Scale" : "Scale"}</Typography>
								{/* <Typography variant="body1" color="textSecondary">
									{shuffleVariant === 0 ?
										"SaaS / Cloud"
										:
										"Licensed / On-prem"
									}
								</Typography> */}

								<Typography style={{ fontSize: 36, marginTop: 18, minHeight: 62, cursor: calculatedCores === "Get A Quote" ? "pointer" : "inherit", }} onClick={() => {

									if (calculatedCores === "Get A Quote") {
										console.log("Clicked on get a quote")
										if (window.drift !== undefined) {
											window.drift.api.startInteraction({ interactionId: 340785 })
										}
									}
								}}>{calculatedCost}</Typography>
								<Typography variant="body1" color="textSecondary" style={{}}>Per month for {shuffleVariant === 1 ? `${selectedValue} CPU cores` : `${selectedValue}k App Runs`}: </Typography>
								<div>

									<Slider
										aria-label="Small steps"
										style={{ width: "80%", margin: "auto", minHeight: 55 }}
										onChange={(event, newValue) => {
											handleChange(event, newValue)
										}}
										marks
										value={selectedValue}
										step={shuffleVariant === 0 ? 100 : 4}
										min={shuffleVariant === 0 ? 100 : 8}
										max={shuffleVariant === 0 ? 1000 : 32}
										valueLabelDisplay="auto"
									/>
								</div>
								<div style={{ textAlign: 'left', color: '#fff', borderRadius: '8px' }}>
									<Typography style={itemStyle}>
										{defaultTaskIcon}
										<Typography variant="body1" style={{ marginLeft: '10px' }}>
											{shuffleVariant === 0 ?
												"Unlimited Workflows"
												:
												"Unlimited Workflows, Apps, and Users"
											}
										</Typography>
									</Typography>
									<Divider style={{ backgroundColor: '#444' }} />
									<Typography style={itemStyle}>
										{defaultTaskIcon}
										<Typography variant="body1" style={{ marginLeft: '10px' }}>
											{shuffleVariant === 0 ?
												"Unlimited Apps"
												:
												"Scalable, Fast Workflows"
											}
										</Typography>
									</Typography>
									<Divider style={{ backgroundColor: '#444' }} />
									<Typography style={itemStyle}>
										{defaultTaskIcon}
										<Typography variant="body1" style={{ marginLeft: '10px' }}>
											{shuffleVariant === 0 ?
												"Unlimited Users"
												:
												"High Availability"
											}
										</Typography>
									</Typography>
									<Divider style={{ backgroundColor: '#444' }} />
									<Typography style={itemStyle}>
										{defaultTaskIcon}
										<Typography variant="body1" style={{ marginLeft: '10px' }}>
											{shuffleVariant === 0 ?
												"5 hours of Professional Services"
												:
												"5 hours of Professional Services"
											}
										</Typography>
									</Typography>
									<Divider style={{ backgroundColor: '#444' }} />
									{shuffleVariant === 0 ?
										<Typography style={itemStyle}>
											{defaultTaskIcon}
											<Typography variant="body1" style={{ marginLeft: '10px' }}>
												Priority Support
											</Typography>
										</Typography> : null}
									<Divider style={{ backgroundColor: '#444' }} />
									<Typography style={itemStyle}>
										{defaultTaskIcon}
										<Typography variant="body1" style={{ marginLeft: '10px' }}>
											{shuffleVariant === 0 ?
												"and much more, see below!"
												:
												"and much more, see below!"
											}
										</Typography>
									</Typography>
									{/* <Divider /> */}
									{/* <Typography variant="body1">
										{defaultTaskIcon} Help with Workflow and App development
									</Typography> */}
								</div>
								<div style={{ height: shuffleVariant === 0 ? 61 : 81, }} />
								{/*billingInfo*/}


								{/* {level2Button} */}
								{true ?
									<Button fullWidth disabled={false} variant="contained" color="primary" style={{ textTransform: "capitalize", borderRadius: 25, height: 40, margin: "15px 0px 15px 0px", fontSize: 17, color: "white", backgroundImage: buttonBackground, }} onClick={() => {
										if (isLoggedIn) {
											isLoggedInHandler()


										} else {
											navigate(`/register?view=pricing&message=You need to create a user to continue`)

											//navigate("/login?redirect=/pricing")
											//to={`/register?app_one=${app.name}&app_two=${secondaryApp.name}&message=You need to login first to connect ${app.name} and ${secondaryApp.name}`}
										}
									}}>
										{calculatedCost === payasyougo ?
											"Manage Subscription"
											:
											<span>
												{shuffleVariant === 0
													? `Get ${selectedValue}k App Runs`
													: `Get License for ${selectedValue} CPU Cores`
												}
											</span>
										 }
									</Button>
									:
									null}
							</CardContent>
						</Card>
					</Grid>
					{/* <Grid item xs={isMobile ? 12 : 4}>
						<Card style={{ ...cardStyle, border: "2px solid #f85a3e", backgroundColor:"#212121" }}>

							<CardContent style={{ padding: 35, backgroundColor:"#212121" }}>
								<Typography variant="h4">{shuffleVariant === 1 ? "MSSP" : "MSSP"}</Typography>
								<Typography variant="body1" color="textSecondary">
									{shuffleVariant === 0 ?
										"SaaS / Cloud"
										:
										"Licensed / On-prem"
									}
								</Typography>

								<Typography style={{ fontSize: calculatedCostMSSP === "Get A Quote" ? 36 : 36, marginTop: 48, minHeight: 62, cursor: calculatedCostMSSP === "Get A Quote" ? "pointer" : "inherit", }} onClick={() => {

									if (calculatedCostMSSP === "Get A Quote") {
										console.log("Clicked on get a quote")
										if (window.drift !== undefined) {
											window.drift.api.startInteraction({ interactionId: 340785 })
										}
									}
								}}>{calculatedCostMSSP}</Typography>
								<Typography variant="body1" color="textSecondary" style={{}}>Per month for {shuffleVariant === 1 ? `${selectedValueMSSP} CPU cores` : `${selectedValueMSSP}k App Runs`}: </Typography>
								<div>

									<Slider
										aria-label="Small steps"
										style={{ width: "80%", margin: "auto" }}
										onChange={(event, newValue) => {
											handleChangeMSSP(event, newValue)
										}}
										marks
										value={selectedValueMSSP}
										step={shuffleVariant === 0 ? 100 : 4}
										min={shuffleVariant === 0 ? 100 : 8}
										max={shuffleVariant === 0 ? 1000 : 32}
										valueLabelDisplay="auto"
									/>
								</div>
								<div style={{ textAlign: 'left',  color: '#fff', borderRadius: '8px' }}>
									<Typography style={itemStyle}>
										{defaultTaskIcon} <Typography variant="body1" style={{ marginLeft: 10, marginTop: 10 }}>Priority Support</Typography>
									</Typography>
									<Divider style={{ backgroundColor: '#444' }} />
									<Typography style={itemStyle}>
										{defaultTaskIcon}
										<Typography variant="body1" style={{ marginLeft: '10px' }}>
											{shuffleVariant === 0 ?
												"5 hours of Professional Support included"
												:
												"5 hours of Professional Support included"
											}
										</Typography>
									</Typography>
									<Divider style={{ backgroundColor: '#444' }} />
									<Typography style={itemStyle}>
										{defaultTaskIcon}
										<Typography variant="body1" style={{ marginLeft: '10px' }}>
											{shuffleVariant === 0 ?
												"Professional Support @$159/hour"
												:
												"Professional Support @$159/hour"
											}
										</Typography>
									</Typography>
									<Divider style={{ backgroundColor: '#444' }} />
									<Typography style={itemStyle}>
										{defaultTaskIcon}
										<Typography variant="body1" style={{ marginLeft: '10px' }}>
											Multi-Tenant and Multi-Regions
										</Typography>
									</Typography>
									<Divider style={{ backgroundColor: '#444' }} />
									<Typography style={itemStyle}>
										{defaultTaskIcon}
										<Typography variant="body1" style={{ marginLeft: '10px' }}>
											See feature breakdown below
										</Typography>
									</Typography>
								</div>
								<div style={{ height: 30, }} />
								{true ?
									<Button fullWidth disabled={false} variant="contained" color="primary" style={{ textTransform: "capitalize", borderRadius: 25, height: 40, margin: "15px 0px 15px 0px", fontSize: 17, color: "white", backgroundImage: buttonBackground, }} onClick={() => {
										if (isLoggedIn) {
											handleMSSPCheckout()


										} else {
											navigate(`/register?view=pricing&message=You need to create a user to continue`)
										}
									}}>
										{calculatedCostMSSP === payasyougo ?
											"Manage Subscription"
											:
											<span>Get {selectedValueMSSP}{shuffleVariant === 0 ? "k App Runs" : " Scale License"}</span>
										}
									</Button>
									:
									null}
							</CardContent>
						</Card>
					</Grid> */}

				</Grid>
			</div>

			<div style={{ maxWidth: maxwidth, margin: isMobile ? "auto" : "auto", }}>
				<Typography color="textSecondary" style={{ marginTop: 65, marginBottom: 45, textAlign: isMobile ? "center" : "center" }}>
					{shuffleVariant === 0 ? (
						<Typography variant="body2">
							100k App Runs per month can handle ~1000 alerts, ~500 Assets or 30.000 EPS, and scales linearly.<br /> Listed prices are in USD, exclude VAT, and the numbers above are estimates. <br />Buying license implies agreement to{' '}
							<Link to="/docs/terms_of_service" style={{ textDecoration: "none", color: theme.palette.primary.main }}>
								license T&C
							</Link>
							. We use Stripe Checkout or Wire transfer for payments.
						</Typography>
					) : (
						<Typography variant="body2">
							8 cores supports ~1500 Assets, ~3000 alerts or ~90.000 EPS, and scales linearly. Kubernetes uses Cloud/App Run pricing.<br /> Listed prices are in USD, exclude VAT, and the numbers above are estimates. <br />Buying license implies agreement to{' '}
							<Link to="/docs/terms_of_service" style={{ textDecoration: "none", color: theme.palette.primary.main }}>
								license T&C
							</Link>
							. We use Stripe Checkout or Wire transfer for payments.
						</Typography>
					)}
					<div />
				</Typography>
				{/*
				<Typography color="textSecondary" style={{marginTop: 25, textAlign: isMobile ? "left" : "center",}}>
					Shuffle is an <a rel="noreferrer noopener" target="_blank" style={{textDecoration: "none", color: "#f85a3e",}} href="https://github.com/shuffle/shuffle">Open Source</a> project. Gives access to support, development and features not otherwise available. This applies to both Open Source & Cloud/SaaS. After the transaction is finished, you will immediately have full access to our support team, and you organization will automatically get upgraded resources assigned. 
				</Typography>
				*/}
				<Grid container spacing={2} style={{ width: "100%", marginTop: isMobile ? null : 75, margin: isMobile ? "auto" : "", marginBottom: 140, alignItems: "center", justifyContent: "center" }}>
					<Grid item xs={isMobile ? 12 : 5} style={{ textAlign: "center", marginTop: 50, borderRadius: 16, padding: 24, backgroundColor: "#212121" }}>
						<Typography variant="h4" style={{ marginBottom: 10, color:"#FFFFFF" }}>Volume Discounts</Typography>
						<Typography variant="body1" style={{ marginBottom: 10 }}>
							Discounts trigger automatically as your usage grows, so you always get a fair price.
						</Typography>
					</Grid>
					<Grid item xs={isMobile ? 12 : 5} style={{ textAlign: "center", marginTop: 50, marginLeft: isMobile ? 0 : 20, borderRadius: 16, padding: 24, backgroundColor: "#212121" }}>
						<Typography variant="h4" style={{ marginBottom: 10,color:"#FFFFFF" }}>Commitment Discounts</Typography>
						<Typography variant="body1" style={{ marginBottom: 10 }}>
							Get additional discounts for annual or multi-year commitments. <Link to="/contact" style={{ textDecoration: "none", color: theme.palette.primary.main }}>Contact us</Link>.
						</Typography>
					</Grid>
					<Grid item xs={isMobile ? 12 : 5} style={{ textAlign: "center", marginTop: isMobile ? 50 : 20, borderRadius: 16, padding: 24, backgroundColor: "#212121" }}>
						<Typography variant="h4" style={{ marginBottom: 10, color:"#FFFFFF" }}>Training</Typography>
						<Typography variant="body1" style={{ marginBottom: 10 }}>
							We teach you how to become a power user of Shuffle. Learn more <Link to="/training" style={{ textDecoration: "none", color: theme.palette.primary.main }}>here</Link>.
						</Typography>
					</Grid>
					<Grid item xs={isMobile ? 12 : 5} style={{ textAlign: "center", marginTop: isMobile ? 50 : 20, marginLeft: isMobile ? 0 : 20, borderRadius: 16, padding: 24, backgroundColor: "#212121" }}>
						<Typography variant="h4" style={{ marginBottom: 10, color:"#FFFFFF" }}>Professional Services</Typography>
						<Typography variant="body1" style={{ marginBottom: 10 }}>
							We help you with custom development, workflow, and app creation among other things. Learn more <Link to="/professional-services" style={{ textDecoration: "none", color: theme.palette.primary.main }}>here</Link>.
						</Typography>
					</Grid>
					<Grid item xs={isMobile ? 12 : 5} style={{ textAlign: "center", marginTop: isMobile ? 50 : 20, marginLeft: isMobile ? 0 : 20, borderRadius: 16, padding: 24, backgroundColor: "#212121" }}>
						<Typography variant="h4" style={{ marginBottom: 10, color:"#FFFFFF" }}>Community</Typography>
						<Typography variant="body1" style={{ marginBottom: 10 }}>
							Shuffle has a rich community of people from security, analyst, tech, dev, and cybersec industry. <a
								href="https://discord.gg/B2CBzUm"
								target="_blank"
								rel="noopener noreferrer"
								style={{ textDecoration: "none", color: theme.palette.primary.main }}
							>
								Join here
							</a>.
						</Typography>
					</Grid>
				</Grid>

				{
					!showPricing ? null : (
						<Grid container style={{ justifyContent: isMobile ? "center" : "center" }}>
							<Typography
								variant="h4"
								style={{ marginBottom: 50, textAlign: "center" }}
							>
								All Features ({shuffleVariant === 0 ? "Cloud" : "On-Prem"})
							</Typography>
							<Grid item style={{ width: "auto", flexDirection: "no-wrap", border: "1px solid #494949", height: "fit-content", padding: 20, fontSize: '16px', color: '#ffffff', backgroundColor: '#1A1A1A', borderRadius: '16px', }}>
								<TableContainer style={{ boxShadow: "none", display: "flex", maxWidth: isMobile ? 300 : 1000, minWidth: isMobile ? 300 : 1000 }} component={Paper}>
									<Table sx={{ minWidth: 650 }} aria-label="features table">
										<TableHead>
											<TableRow style={{ backgroundColor: "#1A1A1A", }}>
												<TableCell align='left' style={{ fontSize: 18, fontWeight: 600 }}>Feature</TableCell>
												<TableCell align="center" style={{ fontSize: 18, fontWeight: 600 }}>{shuffleVariant === 0 ? "Free" : "Open Source"}</TableCell>
												<TableCell align="center" style={{ fontSize: 18, fontWeight: 600 }}>{shuffleVariant === 0 ? "Scale" : "Speed & Scale"}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{shuffleVariant === 0 ? (
												<>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Users</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>5</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Unlimited</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Apps</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>50</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Unlimited</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Workflows</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>10</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Unlimited</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>App Runs</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>10k</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>As per License</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Professional Services</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>0 Hours</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>5 Hours</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>File Storage</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>Max 1 GB</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Default 100 GB</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Shuffle SMS Alerting</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>30 / month</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>300 / month</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Shuffle Email Alerting</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>100 / month</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>10.000 / month</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Multi-Tenants</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>Max 3</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Unlimited</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Billing Control & Alerts</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Detailed App-Run tracking</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Datacenter Region Selection</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Audit logging</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Priority Support</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Personal Onboarding</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Email & Chat Support</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>Standard</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Prioritized (as per SLA)</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Uptime SLA</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>99.9%</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Automatic Backups</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Execution Retention</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>1 month</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>12 months</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Data LOCATION control</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Management dashboard</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Log Ingestion (Data Lake)</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Data Lake Searching (SIEM)</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
												</>
											) : (
												<>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Scalable, Fast Workflows</TableCell>
														<Tooltip title="Self-controlled without support">
															<TableCell align="center" style={{ width: 130 }}>
																<WarningIcon style={{height: 20, width: 20, }}/>
															</TableCell>
														</Tooltip>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>High Availability</TableCell>
														<Tooltip title="Self-controlled without support">
															<TableCell align="center" style={{ width: 130 }}>
																<WarningIcon style={{height: 20, width: 20, }}/>
															</TableCell>
														</Tooltip>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Per-Core Support</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>0 / month</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>As per License</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Users</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>Unlimited</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Unlimited</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Apps</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>Unlimited</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Unlimited</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>App Runs</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>Unlimited</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Unlimited</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Professional Services</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>0 Hours</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>5 Hours</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>File Storage</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>✔</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Shuffle SMS Alerting</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>30 / month</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>300 / month</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Shuffle Email Alerting</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>100 / month</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>10.000 / month</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Multi-Tenants</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>Max 3</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Unlimited</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Billing Control & Alerts</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Detailed App-Run tracking</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Datacenter Region Selection</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Audit logging</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Priority Support</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Personal Onboarding</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Maintenance & Updates</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Email & Chat Support</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>Standard</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>Prioritized (as per SLA)</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Data LOCATION control</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Management dashboard</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Log Ingestion (Data Lake)</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
													<TableRow>
														<TableCell align='left' style={{ fontSize: 18, fontWeight: 400, backgroundColor: "#1A1A1A", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start" }}>Data Lake Searching (SIEM)</TableCell>
														<TableCell align="center" style={{ fontSize: 18, width: 130 }}>❌</TableCell>
														<TableCell align="center" style={{ fontSize: 18 }}>✔</TableCell>
													</TableRow>
												</>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					)
				}
			</div>
			<div style={{
				padding: isMobile ? null : "100px",
				textAlign: "center",
				color: "rgba(255,255,255,0.9)",
				// minWidth: "100%",
				width: isMobile ? "100%" : 1000,
				margin: "auto",
				paddingTop: isMobile? 100:150,
			}}>
				<Typography variant="h4" style={{ textAlign: "center", marginBottom: isMobile ? 50 : 50, margin: isMobile ? 20 : "" }}>
					Frequently Asked Questions
				</Typography>
				<div style={{
					// display: 'grid',
					// gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)',
					gap: '5px',
					alignItems: "center",
					width: "100%",
					paddingTop: 40,
					margin: "auto",
					borderBottom: "0.5px #FF8444 solid"
				}}>
					{questions.map((data, index) => (
						<div key={index} style={{
							// padding: 10,
							textAlign: 'center',
							// gridColumn: index === questions.length - 1 && !isMobile ? 'span 2 / span 2' : 'auto',
							// justifySelf: index === questions.length - 1 && !isMobile ? 'auto' : 'auto',
						}}>
							<div style={{ width: "100%", border: '0.5px #FF8444 solid' }} />
							<FAQList isPriceing={isPriceing} setIsPriceing={setIsPriceing} question={data.question} answer={data.answer} />
							{/* <div style={{ width: "100%", height: 0, border: '0.5px #FF8444 solid' }} /> */}
						</div>
					))}
					{/* <div style={{ width: "100%", height: 0, border: '0.5px #FF8444 solid' }} /> */}
				</div>
			</div>

			<div style={{ marginTop: isMobile ? 50:20, zIndex: 10, margin: isMobile ? null : "auto" }}>
				<Typography style={{ fontSize: 24, textAlign: isMobile ? "center" : null, color: "#F1F1F1", padding: isMobile?50:null }}><b style={{ fontSize: 35 }}>Got other questions?</b><br /> If you got more questions about our pricing and plans,<br /> please <a href='/contact' style={{ textDecoration: "none", color: "#FF8444" }}>contact us</a> so we can help. </Typography>
			</div>

		</div>

	const setMonthlyCost = (variant, paymentType) => {
		setErrorMessage("")
		if (variant === 0 && paymentType === 0) {
			setCurrentPrice(129)
		} else if (variant === 0 && paymentType === 1) {
			setCurrentPrice(155)
		} else if (variant === 1 && paymentType === 0) {
			setCurrentPrice(999)
		} else if (variant === 1 && paymentType === 1) {
			setCurrentPrice(1199)
		} else if (variant === 2 && paymentType === 0) {
			setCurrentPrice(15)
		} else if (variant === 2 && paymentType === 1) {
			setCurrentPrice(18)
		}
	}

	const modalView = modalOpen ?
		<Dialog
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				ReactGA.event({
					category: "pricing",
					action: `close_window_outside_click`,
					label: "",
				})
			}}
			PaperProps={{
				style: {
					backgroundColor: "#1f2023",
					color: "white",
					minWidth: isMobile ? "100%" : 500,
					padding: 30,
				},
			}}
		>
			<FormControl>
				<DialogTitle><div style={{ color: "white" }}>Shuffle payments</div></DialogTitle>
				<DialogContent>
					<Typography variant="body1">
						Choose recurrence
					</Typography>
					<ButtonGroup style={{ height: 50 }} color="primary" aria-label="outlined secondary button group">
						<Button style={{ width: 100 }} variant={paymentType === 0 ? "contained" : "outlined"} onClick={() => {
							setPaymentType(0)
							setMonthlyCost(variant, 0)
							ReactGA.event({
								category: "pricing",
								action: `yearly_modal_click`,
								label: "",
							})
						}}>Yearly</Button>
						<Button style={{ width: 100 }} variant={paymentType === 1 ? "contained" : "outlined"} onClick={() => {
							setPaymentType(1)
							setMonthlyCost(variant, 1)
							ReactGA.event({
								category: "pricing",
								action: `monthly_modal_click`,
								label: "",
							})
						}}>Monthly</Button>
					</ButtonGroup>
					<div />
					<Card style={{ marginTop: 25, backgroundColor: theme.palette.surfaceColor }}>
						<CardContent style={{ padding: 25 }}>
							<Typography color="textSecondary">
								Your plan:
							</Typography>
							<Typography color="textSecondary">
								Shuffle <b>{variant === 0 ? "Community" : variant === 2 ? "Free" : "Pro"}</b> Edition
							</Typography>
							<Divider style={{ marginTop: 15, marginBottom: 15 }} />
							<div style={{ display: "flex" }}>
								<Typography color="textSecondary" style={{ flex: 1 }}>
									Monthly subtotal:
								</Typography>
								<Typography variant="h6" color="textSecondary" style={{ flex: 1, textAlign: "right" }}>
									${currentPrice}
								</Typography>
							</div>
							<div style={{ display: "flex" }}>
								<Typography color="textSecondary" style={{ flex: 1 }}>
									Discount:
								</Typography>
								<Typography variant="h6" color="textSecondary" style={{ flex: 1, textAlign: "right" }}>
									{paymentType === 0 ? "20%" : "0%"}
								</Typography>
							</div>
							<div style={{ display: "flex" }}>
								<Typography color="textSecondary" style={{ flex: 1 }}>
									Beta opt-in:
								</Typography>
								<Typography variant="h6" color="textSecondary" style={{ flex: 1, textAlign: "right" }}>
									Extra features
								</Typography>
							</div>
							<Divider style={{ marginTop: 15, marginBottom: 15 }} />
							<div style={{ display: "flex" }}>
								<Typography color="textSecondary" style={{ flex: 1 }}>
									What you'll pay now:
								</Typography>
								<Typography color="textSecondary" variant="h4" style={{ flex: 1, textAlign: "right" }}>
									${paymentType === 0 ? currentPrice * 12 : currentPrice}
								</Typography>
							</div>
						</CardContent>
					</Card >



					<Button variant="contained" color="primary" style={{ height: 50, marginTop: 25, width: 200, }} onClick={() => {
						handleStripeRedirect(variant, paymentType)
					}}>
						Pay now
					</Button>
					<Typography variant="body1" style={{ marginTop: 15 }}>
						Your plan will renew each {paymentType === 0 ? "year" : "month"}.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button style={{}} onClick={() => {
						setModalOpen(false)
						ReactGA.event({
							category: "pricing",
							action: `close_window_cancel_click`,
							label: "",
						})
					}} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</FormControl>
		</Dialog>
		: null

	return (
		<Wrapper isLoggedIn={isLoggedIn}>
			{topRet}
			{modalView}
		</Wrapper>
	)
}

export default PaymentField;

const PaddingWrapper = ({children, isLoggedIn})=>{

	const { leftSideBarOpenByClick } = useContext(Context)
	return(
		<div style={{zoom: 0.8, padding: 20, marginLeft: isLoggedIn ? leftSideBarOpenByClick ? 200 : 0 : 0, transition: "margin-left 0.3s ease"}}>
			{children}
		</div>
	)
}

const Wrapper = memo(({children,isLoggedIn})=>{

	return(
		<PaddingWrapper isLoggedIn={isLoggedIn}>
			{children}
		</PaddingWrapper>
	)
})
